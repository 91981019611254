import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import quotes from "../components/quotes"
import FamousQuotes from "../components/famous-quotes"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Fade from "@material-ui/core/Fade"
import Grow from "@material-ui/core/Grow"

var heropic = require("../../content/assets/stars.gif")
var heropic = require("../../content/assets/fronds.png")
var floral = require("../../content/assets/floral-1.jpg")
var leaves = require("../../content/assets/leaves.jpg")
var plants = require("../../content/assets/plants-5.jpg")
// var size = Object.keys({quotes}).length;

class BlogIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      in: false,
      //interval: null,
    }
    this.quotesRef = React.createRef()
    this.handleQuotes = this.handleQuotes.bind(this)
    this.timing = this.timing.bind(this)
  }

  timing(quotes) {
    setInterval(() => {
      this.handleQuotes(quotes)
    }, 5000)
  }

  handleQuotes(quotes) {
    if (this.state.count < 4) {
      this.setState({
        count: this.state.count + 1,
      })
      //this.quotesRef.current.handleQuotes(this.state.count);
    } else {
      this.setState({
        count: 0,
      })
    }
  }

  componentDidMount() {
    this.timing({ quotes })
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
    // clearInterval(this.interval);
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All blog posts"
          description="A directory of the blog posts and essays Liz has written 
          on various topics of personal interest including literature, programming, writing, and design."
        />
        <Box
          className="white-page"
          bgcolor="primary.light"
        //style={{
        // marginLeft: `auto`,
        // marginRight: `auto`,
        // maxWidth: rhythm(24),
        //padding: `${rhythm(1.5)} ${rhythm(8 / 4)}`,
        //}}
        >
          <Box
            //className="block-header hero-image"
            bgcolor="primary.dark"
          //color='primary.light'
          //style={{
          //padding: '80px',
          //backgroundImage: 'url('+heropic+')',
          //background:'linear-gradient(to bottom, rgba(31,23,24,1) 0%, rgba(31,23,24,0.8) 50%, rgba(138,106,109,0) 100%)',
          //}}
          >
            <Box
              className="block-header hero-image"
              style={{
                backgroundImage: "url(" + floral + ")",
                backgroundAttachment: "fixed",
              }}
            >
              <Box
                className="block-header-gradient mobile-column"
                color="primary.light"
              //style={{
              //background:'linear-gradient(to bottom, rgba(31,23,24,1) 0%, rgba(31,23,24,0.8) 50%, rgba(138,106,109,0) 100%)',
              //}}
              >
                <Grow in={true} timeout={1000}>
                  <Box>
                    <h1 className="alt-big-title" id="blog-page-title">
                      a blog.
                    </h1>
                    <h2 className="alt-medium-title" id="blog-page-title">
                      Thoughts on literature, programming, & other things
                    </h2>
                  </Box>
                </Grow>
                <Box id="blog-page-quote-box">
                  <p id="quote-block">"{quotes[this.state.count].quote}"</p>
                  <cite className="block-description">
                    - {quotes[this.state.count].author}
                  </cite>
                </Box>
              </Box>
            </Box>
          </Box>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const topSubtitle = node.frontmatter.topSubtitle
            return (
              <Box className="blog-list">
                <article key={node.fields.slug}>
                  <Box>
                    <header>
                      <Link
                        style={{ boxShadow: `none`, color: `#8A6A6D` }}
                        to={node.fields.slug}
                      >
                        <b>
                          <h4
                            className="blog-list-title"
                            style={{
                              marginBottom: "0px",
                            }}
                          >
                            {topSubtitle}
                          </h4>

                          <h3
                            className="blog-list-title"
                            style={{
                              marginBottom: rhythm(1 / 4),
                            }}
                          >
                            {title}
                          </h3>
                        </b>
                      </Link>
                      <small>{node.frontmatter.date}</small>
                    </header>

                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </section>
                  </Box>
                </article>
              </Box>
            )
          })}
        </Box>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            topSubtitle
            description
          }
        }
      }
    }
  }
`
