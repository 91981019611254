import Fade from '@material-ui/core/Fade';

export const quotes = [
   {
    quote: 'We believe it is our right and responsibility to write ourselves into the future.',
    author: 'Walidah Imarisha & adrienne maree brown'
   },
   {
     quote: 'Writing is magic, as much the water of life as any other creative art. The water is free. So drink. Drink and be filled up.',
     author: 'Stephen King',
    },
    {
     quote: 'I speak to the paper, as I speak to the first person I meet.',
     author: 'Michel de Montaigne',
    },
    {
     quote: 'Instead of talking your ear off, I should just start a blog.',
     author: 'Liz to a friend after rambling for like an hour',
    },
    {
     quote: 'Ideas are like rabbits. You get a couple and learn how to handle them, and pretty soon you have a dozen.',
     author: 'John Steinbeck',
    },
  ];

  export default quotes;