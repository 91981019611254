import React from "react";
import Fade from '@material-ui/core/Fade';


export default class FamousQuotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            in: false,
        };
        this.handleQuotes = this.handleQuotes.bind(this);
    }
    handleQuotes(count){
      this.setState({
          index: count,
        });
    }

    render() {
        var count = this.props;
        const quotes = [
           {
             quote: 'Writing is magic, as much the water of life as any other creative art. The water is free. So drink. Drink and be filled up.',
             author: 'Stephen King',
            },
            {
             quote: 'I speak to the paper, as I speak to the first person I meet.',
             author: 'Michel de Montaigne',
            },
            {
             quote: 'Instead of talking your ear off, I should just start a blog.',
             author: 'Liz to a friend after talking for 2 hours',
            },
             {
             quote: 'We are what we repeatedly do. Excellence then, is not an act, but a habit.',
             author: 'Aristotle',
            },
            {
             quote: 'Ideas are like rabbits. You get a couple and learn how to handle them, and pretty soon you have a dozen.',
             author: 'John Steinbeck',
            },
          ];
        return (
            <Fade
             in={true}
             timeout={1000}
             >
               <p>
                {quotes[this.state.index].quote}
                <br/>
                - {quotes[this.state.index].author}
                </p>
            </Fade>    
        )
    }
} 


// export default FamousQuotes